<template>
  <v-container>
    <!-- <v-row
        class="mb-6"
        justify="center"
        no-gutters>
          로그잉
      </v-row> -->
    <v-card-title> 회원가입 </v-card-title>
    <v-card-text>
      <v-alert
        v-if="alertSuccess"
        dense
        text
        type="success"
      >
        <strong>회원가입 성공 !</strong>
      </v-alert>
      <v-alert
        v-if="alertFail"
        dense
        text
        type="error"
      >
        <strong>회원가입 실패</strong>
      </v-alert>
      <v-form>
        <v-text-field
          v-model="email"
          :error-messages="emailErrors"
          label="이메일"
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
        />
        <v-text-field
          v-model="pw"
          :error-messages="pwErrors"
          label="비밀번호"
          :append-icon="isShow ? 'mdi-eye' : 'mdi-eye-off'"
          :type="isShow ? 'text' : 'password'"
          required
          @click:append="isShow = !isShow"
          @input="$v.pw.$touch()"
          @blur="$v.pw.$touch()"
        />
        <v-checkbox
          v-model="checkbox"
          :error-messages="checkboxErrors"
          label="회원가입하셔야 테스트 제작소를 이용하실 수 있습니다."
          required
          @change="$v.checkbox.$touch()"
          @blur="$v.checkbox.$touch()"
        />
        <v-row>
          <v-col
            cols="12"
            sm="3"
            md="3"
            lg="3"
            xl="3"
          >
            <v-btn
              block
              class="mr-4"
              @click="clear"
            >
              지우기
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="9"
            md="9"
            lg="9"
            xl="9"
          >
            <v-btn
              color="primary"
              block
              @click="submit"
            >
              가입
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, email, password } from 'vuelidate/lib/validators'
import { mapMutations, mapActions } from 'vuex'

export default {
  mixins: [validationMixin],

  validations: {
    email: { required, email },
    pw: { required },
    instagram: '',
    checkbox: {
      checked(val) {
        return val
      },
    },
  },

  data: () => ({
    tabs: null,
    text: 'abcd',
    id: '',
    pw: '',
    email: '',
    instagram: '',
    isShow: false,
    checkbox: false,
    alertFail: false,
    alertSuccess: false,
  }),

  computed: {
    checkboxErrors() {
      const errors = []
      if (!this.$v.checkbox.$dirty) return errors
      !this.$v.checkbox.checked && errors.push('You must agree to continue!')
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('제대로 된 이메일을 입력하세요')
      !this.$v.email.required && errors.push('이메일을 입력하세요')
      return errors
    },
    pwErrors() {
      const errors = []
      if (!this.$v.pw.$dirty) return errors
      !this.$v.pw.required && errors.push('비밀번호를 입력해주세요')
      return errors
    },
  },

  methods: {
    ...mapMutations('common', ['setLoading']),
    ...mapActions('user', ['addUser']),
    submit() {
      this.alertSuccess = false
      this.alertFail = false
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.setLoading(true)
      this.addUser({
        email: this.email,
        password: this.pw,
      }).then(res => {
        if (res == 'ERROR') {
          this.alertFail = true
        } else {
          this.alertSuccess = true
          setTimeout(() => {
            this.$router.push('/')
          }, 1000)
        }
        this.setLoading(false)
      })
    },
    clear() {
      this.$nextTick(() => {
        this.$v.$reset()
      })

      this.email = ''
      this.instagram = ''
      this.pw = ''
      this.checkbox = false
    },
  },
  watch: {
    isShow() {},
  },
  mounted() {},
}
</script>
